import React, { FunctionComponent, createRef, useEffect, useState } from 'react'
import {
  Keyboard,
  Image,
  KeyboardAvoidingView,
  ScrollView,
  TextInput,
  TouchableOpacity,
  View,
  Text,
  StyleSheet,
  Linking,
} from 'react-native'
import { checkRegistrationToken, registration } from '../api/user/user.query'
import Colors, { ObserverColors } from '../constants/Colors'
import { extractParamFromUrl, getHostFromUrl } from '../utils/UrlHandler'
import CustomCheckbox from './CustomCheckboxComponent'
import LinkText from './LinkTextComponent'
import SuccessRegistrationComponent from './SuccessRegistrationComponent'
import InvalidRegistrationTokenComponent from './InvalidRegistrationTokenComponent'
import { Spinner } from './Spinner'

import getBundleContent from '../utils/getBundle'

const imgSrc = getBundleContent()?.logo

export interface IRegistrationProps {
  url: string
}

export const RegistrationComponent: FunctionComponent<IRegistrationProps> = (
  props
) => {
  const { url } = props
  const token = extractParamFromUrl(url, 't')

  const nameInputRef = createRef<TextInput>()
  const phoneInputRef = createRef<TextInput>()
  const emailInputRef = createRef<TextInput>()

  const [errors, setErrors] = useState(new Map<string, string>())

  const [formState, setFormState] = useState({
    Email: '',
    PhoneNumber: '',
    Name: '',
    Token: token,
  })

  const [onFocusEmail, setOnFocusEmail] = useState(false)
  const [onFocusName, setOnFocusName] = useState(false)
  const [onFocusPhone, setOnFocusPhone] = useState(false)
  const [isTermsRead, setIsTermsRead] = useState<boolean>(false)
  const [successRegistration, setSuccessRegistration] = useState<boolean>(false)
  const [isValidToken, setValidToken] = useState<boolean>(true)
  const [isLoading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    const checkToken = async () => {
      try {
        const response = await checkRegistrationToken(token)

        setValidToken(response?.isValidToken)
        setLoading(false)
      } catch (error) {
        setValidToken(false)
      }
    }

    checkToken()
  }, [url])

  const handleCheckboxChange = () => {
    setIsTermsRead(!isTermsRead)
  }

  const openTerms = async () => {
    var baseUrl = getHostFromUrl(url)
    if (token == null || token == '') return
    return await Linking.openURL(`https://${baseUrl}/terms?t=${token}`)
  }

  const handleRegistration = async () => {
    const { Email, PhoneNumber, Name, Token } = formState
    let newErrors = new Map(errors)
    const phoneNumberPattern = /^36\d{9}$/
    try {
      newErrors.set('name', !Name ? 'Hiányzik a név' : '')
      newErrors.set('email', !Email ? 'Az email cím megadása kötelező!' : '')
      newErrors.set(
        'phone',
        !PhoneNumber ? 'A telefonszám megadása kötelező!' : ''
      )
      newErrors.set('terms', !isTermsRead ? 'Az ÁSZF elfogadása kötelező!' : '')

      if (PhoneNumber) {
        newErrors.set(
          'phone',
          !phoneNumberPattern.test(PhoneNumber)
            ? 'Hibás a telefonszám formátuma!'
            : ''
        )
      }

      if (
        Name &&
        Email &&
        PhoneNumber &&
        phoneNumberPattern.test(PhoneNumber) &&
        Token &&
        isTermsRead
      ) {
        await registration({
          Email,
          PhoneNumber,
          Name,
          Token,
        })
        setSuccessRegistration(true)
        newErrors = new Map<string, string>()
      }

      setErrors(newErrors)
    } catch (error: any) {
      newErrors.set('internal', error.message)
      setSuccessRegistration(false)
      setErrors(newErrors)
    }
  }

  if (isLoading) {
    return (
      <View style={styles.container}>
        <View style={styles.mainBody}>
          <Spinner isLoading={isLoading}></Spinner>
        </View>
      </View>
    )
  }

  if (!isValidToken) {
    return (
      <View style={styles.container}>
        <View style={styles.mainBody}>
          <InvalidRegistrationTokenComponent />
        </View>
      </View>
    )
  }

  if (successRegistration)
    return (
      <View style={styles.container}>
        <View style={styles.mainBody}>
          <ScrollView
            keyboardShouldPersistTaps="handled"
            showsVerticalScrollIndicator={false}
            showsHorizontalScrollIndicator={false}
            contentContainerStyle={{
              flex: 1,
              marginTop: 25,
              alignContent: 'center',
              justifyContent: 'center',
            }}
          >
            <SuccessRegistrationComponent userName={formState.Name} />
          </ScrollView>
        </View>
      </View>
    )

  return (
    <View style={styles.container}>
      <View style={styles.mainBody}>
        <ScrollView
          keyboardShouldPersistTaps="handled"
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
          contentContainerStyle={{
            flexGrow: 1,
            marginTop: 25,
            alignContent: 'center',
            justifyContent: 'center',
          }}
        >
          <View>
            <KeyboardAvoidingView enabled>
              <View style={styles.imageWrapper}>
                <Image source={{ uri: imgSrc }} style={styles.image}></Image>
              </View>
              <Text style={[styles.description, { marginBottom: 25 }]}>
                {getBundleContent().registrationHeader}
              </Text>
              <View style={styles.horizontalLine} />
              <Text style={styles.title}>Regisztráció</Text>
              <Text style={styles.description}>
                Regisztrációhoz az alábbi adatok megadását kérjük!
              </Text>
              <View style={styles.sectionStyle}>
                <TextInput
                  style={[
                    styles.inputStyle,
                    onFocusName ? styles.onFocus : styles.onBlur,
                  ]}
                  onChangeText={(Name) =>
                    setFormState({
                      ...formState,
                      Name,
                    })
                  }
                  placeholder="Név"
                  placeholderTextColor={Colors.oxfordBlue + 85}
                  keyboardType="default"
                  ref={nameInputRef}
                  onSubmitEditing={Keyboard.dismiss}
                  onFocus={() => {
                    setOnFocusName(true)
                  }}
                  onBlur={() => {
                    setOnFocusName(false)
                  }}
                  blurOnSubmit={false}
                  underlineColorAndroid="transparent"
                  returnKeyType="next"
                />
              </View>
              <View style={styles.sectionStyle}>
                <TextInput
                  style={[
                    styles.inputStyle,
                    onFocusEmail ? styles.onFocus : styles.onBlur,
                  ]}
                  onChangeText={(Email) =>
                    setFormState({
                      ...formState,
                      Email,
                    })
                  }
                  placeholder="Email cím"
                  placeholderTextColor={Colors.oxfordBlue + 85}
                  autoCapitalize="none"
                  textContentType="emailAddress"
                  keyboardType="email-address"
                  returnKeyType="next"
                  underlineColorAndroid="transparent"
                  onSubmitEditing={() =>
                    emailInputRef.current && emailInputRef.current.focus()
                  }
                  blurOnSubmit={false}
                  onFocus={() => {
                    setOnFocusEmail(true)
                  }}
                  onBlur={() => {
                    setOnFocusEmail(false)
                  }}
                />
              </View>
              <View style={styles.sectionStyle}>
                <TextInput
                  style={[
                    styles.inputStyle,
                    onFocusPhone ? styles.onFocus : styles.onBlur,
                  ]}
                  onChangeText={(PhoneNumber) =>
                    setFormState({
                      ...formState,
                      PhoneNumber,
                    })
                  }
                  placeholder="Tel. (36XXXXXXXXX)"
                  placeholderTextColor={Colors.oxfordBlue + 85}
                  keyboardType="default"
                  ref={phoneInputRef}
                  onSubmitEditing={Keyboard.dismiss}
                  onFocus={() => {
                    setOnFocusPhone(true)
                  }}
                  onBlur={() => {
                    setOnFocusPhone(false)
                  }}
                  blurOnSubmit={false}
                  secureTextEntry={false}
                  underlineColorAndroid="transparent"
                  returnKeyType="next"
                />
              </View>
              <View style={styles.errorTextStyle}>
                {Array.from(errors.entries()).map(([key, value]) => {
                  if (value !== '') {
                    return (
                      <Text key={key} style={styles.errorTextStyle}>
                        {value}
                      </Text>
                    )
                  }
                  return null
                })}
              </View>
              <View style={styles.checkBox}>
                <CustomCheckbox
                  isChecked={isTermsRead}
                  onChange={handleCheckboxChange}
                />
                <Text>Elfogadom az</Text>
                <LinkText
                  onPress={() => openTerms()}
                  linkText={'Általános Szerződési Feltételeket'}
                />
              </View>
              <TouchableOpacity
                style={styles.buttonStyle}
                activeOpacity={0.5}
                onPress={async () => handleRegistration()}
              >
                <Text style={styles.buttonTextStyle}>Regisztráció</Text>
              </TouchableOpacity>
            </KeyboardAvoidingView>
          </View>
        </ScrollView>
      </View>
    </View>
  )
}

export default RegistrationComponent
const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    backgroundColor: Colors.listWhite,
  },
  mainBody: {
    flex: 1,
    justifyContent: 'center',
    alignContent: 'center',
    backgroundColor: Colors.white,
    maxWidth: 700,
    width: '100%',
    marginRight: 'auto',
    marginLeft: 'auto',
    paddingRight: 20,
    paddingLeft: 20,
  },
  description: {
    color: Colors.oxfordBlue,
    fontSize: 16,
    textAlign: 'center',
    marginTop: 10,
    fontFamily: 'Roboto_400Regular',
  },
  title: {
    fontSize: 24,
    color: Colors.oxfordBlue,
    textAlign: 'center',
    marginTop: 25,
    marginBottom: 25,
    fontFamily: 'Roboto_500Medium',
  },
  subTitle: {
    fontSize: 16,
    color: Colors.oxfordBlue,
    textAlign: 'center',
    fontFamily: 'Roboto_500Medium',
  },
  sectionStyle: {
    flexDirection: 'row',
    alignSelf: 'center',
    width: '60%',
    margin: 13,
  },
  horizontalLine: {
    height: 1,
    backgroundColor: Colors.oxfordBlue + 90,
    width: '100%',
    alignSelf: 'center',
  },
  buttonStyle: {
    width: 170,
    alignSelf: 'center',
    backgroundColor:
      window.bundle === 'observer' ? ObserverColors.blue : Colors.oxfordBlue,
    height: 40,
    alignItems: 'center',
    borderRadius: 30,
    marginTop: 20,
    marginBottom: 25,
  },
  buttonTextStyle: {
    color:
      window.bundle === 'observer' ? ObserverColors.white : Colors.orangeWeb,
    fontFamily: 'Roboto_500Medium',
    paddingVertical: 10,
    fontSize: 16,
  },
  inputStyle: {
    flex: 1,
    height: 33,
    color: Colors.oxfordBlue,
    paddingLeft: 15,
    paddingRight: 15,
    borderRadius: 100,
    outlineStyle: 'none',
  },
  errorTextStyle: {
    color: 'red',
    textAlign: 'center',
    fontSize: 14,
    width: '80vw',
    alignSelf: 'center',
    marginHorizontal: 13,
  },
  onFocus: { borderWidth: 2, borderColor: Colors.oxfordBlue },
  onBlur: { borderWidth: 1, borderColor: Colors.oxfordBlue },
  imageWrapper: { alignSelf: 'center', marginBottom: 75 },
  image: {
    height: 200,
    width: 200,
  },
  checkBox: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 10,
    flexWrap: 'wrap',
  },
})
