import scopeLogo from '@variants/scope/logo.png'
import mirrorLogo from '@variants/mirror/logo.png'
import scopeIcon from '@variants/scope/icon.png'
import mirrorIcon from '@variants/mirror/icon.png'
import observerLogo from '@variants/observer/logo.png'
import observerIcon from '@variants/observer/icon.png'

const getBundleContent = () =>
  ({
    scope: {
      logo: scopeLogo,
      icon: scopeIcon,
      imageWidth: '35vw',
      registrationHeader:
        'Fedezd fel az általad preferált kategóriájú híreket több száz nemzetközi forrásból! Kérj értesítést a hírekről, mentsd el a legjobb cikkeket vagy oszd meg őket. Egyéni beállításaiddal személyre szabott átfogó képet kapsz a világ napi híreiről!',
      registrationQuestion: 'Kérdés esetén írj nekünk az ',
      registrationEmail: 'info@mirrortheweb.com',
      registrationAddress: 'címre!',
    },
    mirror: {
      logo: mirrorLogo,
      icon: mirrorIcon,
      imageWidth: '35vw',
      registrationHeader:
        'Fedezd fel az általad preferált kategóriájú híreket több száz nemzetközi forrásból! Kérj értesítést a hírekről, mentsd el a legjobb cikkeket vagy oszd meg őket. Egyéni beállításaiddal személyre szabott átfogó képet kapsz a világ napi híreiről!',
      registrationQuestion: 'Kérdés esetén írj nekünk az ',
      registrationEmail: 'info@mirrortheweb.com',
      registrationAddress: 'címre!',
    },
    observer: {
      logo: observerLogo,
      icon: observerIcon,
      imageWidth: '75vw',
      registrationHeader: '',
      registrationQuestion: 'További kérdések esetén írjon nekünk az ',
      registrationEmail: 'info@observer.hu',
      registrationAddress: 'e-mail címre',
    },
  }[window.bundle ?? 'scope'])

export default getBundleContent
